body {
	padding: 0;
	margin: 0;
	overflow: hidden;
	color: var(--base-text-colour);
}

* {
	box-sizing: border-box;
	cursor: default;
	scrollbar-width: thin;
}

.row {
	display: flex;
	flex-direction: row;
}

.spacer {
	width: 1px;
	flex-grow: 1;
}

ol:has(> li[data-list="bullet"]){
  margin-left: 0;
	list-style: disc;
}

ol:has(> li[data-list="ordered"])
{
  list-style-type: decimal;
  padding-left: 40px;
  margin-left: 0;
}

ul:has(> li[data-list="ordered"]) {
  list-style-type: decimal;
}

/* Emergency exceptions */
// div.message__text,
// div.decision-log__box {
// 	user-select: text !important;
// 	cursor: auto !important;
// }
