.update-screen {
	display: flex;
	flex-direction: column;
	align-items: center;

	/* -- */
	background-color: white;
	// color: white;

	.app-header {
		background: var(--white);
		h2 {
			color: var(--heading-text-colour);
		}

		.icon-button {
			.icon {
				color: var(--accent-colour-2);
			}
			&:hover,
			&:active {
				.icon {
					color: var(--white);
				}
			}
		}
	}
	/* -- */

	> p {
		width: 90%;
		text-align: center;
		border-bottom: 1px solid var(--border-colour-1);
		line-height: 31px;
		margin: 0;
		border-top: 1px solid var(--border-colour-1);
	}

	&__content {
		padding: 0rem 1.4rem 2rem 1.4rem;
		overflow: auto;
		flex-grow: 1;

		p {
			/* -- */
			color: black;
			/* -- */
			opacity: 0.9;
			text-align: left;
			white-space: pre-wrap;
			font-weight: normal;
			word-break: break-word;
			font-size: 14px;
		}

		ol {
			font-size: 14px;
			line-height: 22px;
			color: black;
			opacity: 0.9;
		}

		ul {
			font-size: 14px;
			line-height: 22px;
			color: black;
			opacity: 0.9;
		}
	}

	&__translate {
		color: var(--primary-button-colour);
		border-top: solid 1px var(--border-colour-1);

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 50px;
		flex-shrink: 0;

		padding-bottom: 5px;

		cursor: pointer;

		&:hover {
			color: var(--primary-button-hover-colour);
		}
	}

	// .app-header-bar {
	// background-color: $lightlightgrey;
	// color: black;
	// }
}

/******
.virtual-phone.dark-theme {
	.update-screen {
		background-color: transparent;
		// color: white;
		.app-header {
			// background: var(--white);
			.icon-button {
				.icon {
					// color: var(--accent-colour-2);
				}
				&:hover,
				&:active {
					.icon {
						// color: var(--white);
					}
				}
			}
		}

		.update-screen__content {
			p {
				color: white;
			}
		}
	}
}

.virtual-phone.light-theme {
	.update-screen {
		background-color: white;
		color: white;
		.app-header {
			background: var(--white);
			.icon-button {
				.icon {
					color: var(--accent-colour-2);
				}
				&:hover,
				&:active {
					.icon {
						color: var(--white);
					}
				}
			}
		}

		.update-screen__content {
			p {
				color: black;
			}
		}
	}
}
*******/
